/**
 * 対象製品グループに紐づく製品の、構成品親製品のエンティティ（
 */
import { isNud } from '@/utilities/typing';
import Product from '@/models/entities/product';


class ParentComponentProduct {
    product; //構成品親製品
    child_product; //対象製品

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.product = new Product(properties.product);
        this.child_product = new Product(properties.child_product);
    }
}

export default ParentComponentProduct;
