/**
 * ユーザ製品表示項目設定マスタ エンティティ
 */
import { isNud } from '@/utilities/typing'

class UserPreference {
    user_preference_id;           // ユーザ製品表示項目設定ID
    public_type;                  // 公開区分
    availability;                 // ステータス
    pending_date;                 // 取引停止日
    is_each_time;                 // 都度見積
    regular_price;                // 販売価格
    parts_number;                 // Parts No.
    model_number;                 // 製品型式
    is_on_catalog;                // カタログ掲載
    is_price_on_catalog;          // カタログ金額掲載
    production_type;              // 生産区分
    catalogs_specs;               // カタログ用規格項目
    standard_specs;               // 規格図用規格項目
    production_specs;             // 製作図用規格項目
    wholesale_rate_id;            // 掛率記号
    distributor_rate;             // 代理店掛率
    distributor_price;            // 代理店価格
    designated_distributor_rate;  // 指定代理店掛率
    designated_distributor_price; // 指定代理店価格
    group_company_rate;           // グループ会社掛率
    group_company_price;          // グループ会社価格
    shipping_rate;                // 工場出し掛率
    shipping_price;               // 工場出し価格
    factory_rate;                 // 工場掛率
    factory_price;                // 工場価格
    production_cost;              // 原価
    field_1;                      // 項目１
    field_2;                      // 項目２
    field_3;                      // 項目３
    production_hours;             // 作業時間
    product_buffer;               // PB
    product_buffer_red;           // PB(R)
    product_buffer_yellow;        // PB(Y)
    product_buffer_green;         // PB(G)
    product_buffer_limit;         // PB該当数量
    is_retailer_selling;          // 商社別販売ステータス
    retailer_product_code;        // 商社別コード
    retailer_product_name;        // 商社別製品名
    retailer_rate;                // 商社別掛率
    retailer_price;               // 商社別価格

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.user_preference_id = properties.user_preference_id;
        this.public_type = properties.public_type;
        this.availability = properties.availability;
        this.pending_date = properties.pending_date;
        this.is_each_time = properties.is_each_time;
        this.parts_number = properties.parts_number;
        this.model_number = properties.model_number;
        this.is_on_catalog = properties.is_on_catalog;
        this.is_price_on_catalog = properties.is_price_on_catalog;
        this.production_type = properties.production_type;
        this.regular_price = properties.regular_price;
        this.catalogs_specs = properties.catalogs_specs;
        this.standard_specs = properties.standard_specs;
        this.production_specs = properties.production_specs;
        this.wholesale_rate_id = properties.wholesale_rate_id;
        this.distributor_rate = properties.distributor_rate;
        this.distributor_price = properties.distributor_price;
        this.designated_distributor_rate = properties.designated_distributor_rate;
        this.designated_distributor_price = properties.designated_distributor_price;
        this.group_company_rate = properties.group_company_rate;
        this.group_company_price = properties.group_company_price;
        this.shipping_rate = properties.shipping_rate;
        this.shipping_price = properties.shipping_price;
        this.factory_rate = properties.factory_rate;
        this.factory_price = properties.factory_price;
        this.production_cost = properties.production_cost;
        this.field_1 = properties.field_1;
        this.field_2 = properties.field_2;
        this.field_3 = properties.field_3;
        this.production_hours = properties.production_hours;
        this.product_buffer = properties.product_buffer;
        this.product_buffer_red = properties.product_buffer_red;
        this.product_buffer_yellow = properties.product_buffer_yellow;
        this.product_buffer_green = properties.product_buffer_green;
        this.product_buffer_limit = properties.product_buffer_limit;
        this.is_retailer_selling = properties.is_retailer_selling;
        this.retailer_product_code = properties.retailer_product_code;
        this.retailer_product_name = properties.retailer_product_name;
        this.retailer_rate = properties.retailer_rate;
        this.retailer_price = properties.retailer_price;
    }
}

export default UserPreference;
