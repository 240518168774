import Enum from './enum'

/**
 * ユーザ製品表示項目表示タイプ
 */
class preferenceType extends Enum {
    static SHOW = 1; // 表示
    static HIDE = 0; // 非表示

    static values() {
        return {
            [this.SHOW]: '表示',
            [this.HIDE]: '非表示',
        }
    }
}

export default preferenceType;
