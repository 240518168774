import Enum from './enum'

/**
 * リビジョンステータス
 */
class RevisionStatus extends Enum {
    static PENDING = 0; // 未公開
    static PUBLISHED = 1; // 公開中
    static PAST = 2; // 過去

    static values() {
        return {
            [this.PENDING]: '未公開',
            [this.PUBLISHED]: '公開中',
            [this.PAST]: '過去',
        }
    }
}

export default RevisionStatus;
