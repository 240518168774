import Enum from '../enum'

/**
 * 完了状況 [1:未完了 2:完了 3:却下]	
 */
class ApprovedStatus extends Enum {
    static PENDING = 1; // 未完了
    static DONE = 2; // 完了
    static REJECTED = 3; // 却下

    static values() {
        return {
            [this.PENDING]: '未完了',
            [this.DONE]: '完了',
            [this.REJECTED]: '却下',
        }
    }
}

export default ApprovedStatus;
