<template>
    <router-link :to=to class="btn btn-outline-primary" :class="size">
        <i class="bi" :class="icon"></i>
        {{ text }} <slot/>
    </router-link>
</template>


<script>
export default {
    name: 'ButtonGoUpdate',
    props: {
        text: {
            type: String,
            default: '編集',
        },
        icon: {
            type: String,
            default: 'bi-pencil-square',
        },
        type: {
            type: String,
            default: 'button',
        },
        to: {
            type: Object,
            default: () => {
                return {
                    name: null,
                    params: null,
                };
            }
        },
        size: {
            type: String,
            default: ''
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .rotate i:before {transform: scale(-1, 1);}
</style>
