import Enum from './enum'

/**
 * カタログ金額掲載フラグ
 */
class IsPriceOnCatalog extends Enum {
    static YES = 1; // 掲載
    static NO = 0; // なし

    static values() {
        return {
            [this.YES]: '掲載',
            [this.NO]: 'なし',
        }
    }
    static display_values() {
        return {
            [this.YES]: '◯',
            [this.NO]: '✕',
        }
    }

    static getDisplayValues(key) {
        const values = this.display_values();

        if (key in values) {
            return values[key];
        }

        return null;
    }
}

export default IsPriceOnCatalog;
