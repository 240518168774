<template>
    <page-title
        icon="bi-book"
        title="製品情報"
    >
        <button-back
            :to="{name: 'ProductGroupList'}"
            text="製品一覧へ"
        ></button-back>
    </page-title>

    <inline-loader v-if="loading || !is_loaded"></inline-loader>
    <template v-else>
        <section class="section">
            <div class="row">
                <div class="col-20 d-flex mb-3">
                    <div class="h3 me-2">
                        <span class="badge" :class="{
                            'bg-success': (!revision || (revision && revision === product_group.published_revision)),
                            'bg-danger': (revision && revision !== product_group.published_revision)
                        }">{{ product_group.product_group_code }}</span>
                    </div>
                    <div class="flex-grow-1">
                        <h2 :class="{
                            'text-success': (!revision || (revision && revision === product_group.published_revision)),
                            'text-danger': (revision && revision !== product_group.published_revision)
                        }">
                            {{ product_group.product_group_name }} <span v-if="product_group.option_name" class="fs-4 text-secondary">{{ product_group.option_name }}</span>
                        </h2>
                        <h3>
                            {{ product_group.product_group_name_en }} <span v-if="product_group.option_name_en" class="fs-5 text-secondary">{{ product_group.option_name_en }}</span>
                        </h3>
                        <div class="d-flex">
                            <div class="me-3">
                                <small class="fw-bold me-2">最終公開日:</small>
                                <small>{{ product_group.latest_published_date }}</small>
                            </div>
                            <div class="fs-6 ms-2">
                                <!-- 公開区分 -->
                                <span
                                    class="popup badge me-3"
                                    :class="{
                                        'bg-dark': product_group.public_type === PublicType.EXTERNAL_PUBLIC,
                                        'bg-info': product_group.public_type === PublicType.IN_COMPANY_PUBLIC,
                                        'bg-danger': product_group.public_type === PublicType.INVALID,
                                    }"
                                >
                                    {{ PublicType.getShort(product_group.public_type) }}
                                    <span class="text-secondary bg-light rounded-3 shadow-sm p-1">{{ PublicType.get(product_group.public_type) }}</span>
                                </span>
                                <!-- 仕入区分 -->
                                <span
                                    class="popup badge me-3"
                                    :class="{
                                        'bg-dark': product_group.supply_type === SupplyType.OWN,
                                        'bg-secondary': product_group.supply_type === SupplyType.SUPPLY,
                                        'bg-info': product_group.supply_type === SupplyType.OEM,
                                    }"
                                >
                                    {{ SupplyType.getShort(product_group.supply_type) }}
                                    <span class="text-secondary bg-light rounded-3 shadow-sm p-1">{{ SupplyType.get(product_group.supply_type) }}</span>
                                </span>
                                <!-- ステータス -->
                                <span
                                    v-if="product_group.availability != Availability.NORMAL"
                                    class="popup badge me-3"
                                    :class="{
                                        'bg-info': product_group.availability === Availability.TEMPORARY_INFO,
                                        'bg-warning': product_group.availability === Availability.TEMPORARY_PAUSE,
                                        'bg-danger': product_group.availability === Availability.OUT_OF_PRINT,
                                    }"
                                >
                                    {{ Availability.getShort(product_group.availability) }}
                                    <span class="text-secondary bg-light rounded-3 shadow-sm p-1">{{ Availability.get(product_group.availability) }}</span>
                                </span>
                                <!-- 取引停止 -->
                                <template v-if="product_group.pending_date">
                                    <span class="popup badge bg-danger me-3">
                                        取引停止
                                        <span class="text-secondary bg-light rounded-3 shadow-sm p-1">取引停止日: {{ product_group.pending_date_display }}</span>
                                    </span>
                                </template>
                            </div>
                            <div class="ms-2" v-if="!revision || (revision && revision === product_group.published_revision)">
                                <!-- 公開アクセス & 下書きアクセスで公開と同じバージョン の場合 -->
                                <router-link :to="{name: 'ProductGroupHistory', params: {id: product_group_id}}" class="text-decoration-none">
                                    変更履歴へ <i class="bi bi-reply rotate"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="d-flex justify-content-end">
                        <button-go-update
                            v-if="$store.getters['auth/canEditAny']()"
                            :to="{name: 'ProductGroupEdit', params: {id: product_group_id}}"
                            text="編集へ"
                            icon=""
                        >
                            <i class="bi bi-reply rotate"></i>
                        </button-go-update>
                    </div>
                </div>
                <!-- 下書きアクセス で 公開バージョンとバージョンが異なる 場合 -->
                <div class="d-flex mt-2" v-if="revision && revision !== product_group.published_revision">
                    <div class="alert alert-warning" v-if="revision">
                        <template v-if="revision < product_group.published_revision">
                            この製品情報は公開バージョンより過去のバージョンです (Revision {{ revision }})
                        </template>
                        <template v-else-if="revision > product_group.published_revision">
                            この製品情報は未公開バージョンです (Revision {{ revision }})
                        </template>
                        <router-link :to="{name: 'ProductGroupHistory', params: {id: product_group_id}}" class="text-decoration-none ms-2">
                            変更履歴へ <i class="bi bi-reply rotate"></i>
                        </router-link>
                    </div>
                </div>
            </div>

            <!-- 全画像LightBox -->
            <div v-if="lightbox_product_group_all_images.length" class="row mt-4 mb-3">
                <template v-for="image, index in lightbox_product_group_all_images" :key="image">
                    <a class="col-4 image-link" @click="openZoomedAllImageModal(index, lightbox_product_group_all_images)" role="button">
                        <span class="badge bg-light text-dark py-1">{{ image.__category_name }}</span>
                        <div class="img">
                            <img :src="image.thumbnail_url + '?access_token=' + this.$store.state.auth.access_token" :alt="image.file_name" style="max-width:100%;">
                        </div>
                        <div class="file-name">
                            <span v-if="image.file_label">{{ image.file_label }}</span>
                            <span>（{{ image.file_name }}）</span>
                        </div>
                    </a>
                </template>
            </div>
        </section>

        <section class="section" v-if="!loading && is_loaded && product_group.subgroups.length">
            <template v-if="hasSubgroups">
                <ul class="nav nav-tabs mb-3">
                    <template
                        v-for="subgroup, index in product_group.subgroups"
                        :key="subgroup"
                    >
                        <a
                            class="nav-link"
                            style="cursor: pointer;"
                            :class="{'active': index === browsing_subgroup_index}"
                            aria-current="page"
                            @click="setBrowsingSubgroup(subgroup, index)"
                        >{{ subgroup.product_subgroup_name }}</a>
                    </template>
                </ul>

                <div class="row mb-3">
                    <!-- サブグループ全画像LightBox -->
                    <template v-if="lightbox_product_subgroup_all_images.length">
                        <template v-for="image, index in lightbox_product_subgroup_all_images" :key="image">
                            <a class="col-4 image-link" @click="openZoomedAllImageModal(index, lightbox_product_subgroup_all_images)" role="button">
                                <span class="badge bg-light text-dark py-1">{{ image.__category_name }}</span>
                                <div class="img">
                                    <img :src="image.thumbnail_url + '?access_token=' + this.$store.state.auth.access_token" :alt="image.file_name" style="max-width:100%;">
                                </div>
                                <div class="file-name">
                                    <span v-if="image.file_label">{{ image.file_label }}</span>
                                    <span>（{{ image.file_name }}）</span>
                                </div>
                            </a>
                        </template>
                    </template>
                </div>
            </template>

            <div class="d-flex justify-content-end mb-3">
                <button-exec
                    text="表示項目"
                    icon="bi-gear-fill"
                    color="btn-secondary"
                    class="me-3"
                    @click="spec_values_modal = true; generateSpecPreferenceList(); generateRetailerPreferenceList();"
                ></button-exec>
                <div class="dropdown">
                    <a
                        class="btn btn-secondary dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        :class="{'disabled': revision}"
                    >
                        帳票ダウンロード
                    </a>

                    <ul class="dropdown-menu">
                        <!-- 公開されていない または 公開区分が無効 の場合はdisabled -->
                        <li>
                            <a 
                                class="dropdown-item" 
                                :class="{'disabled': revision || product_group.public_type === PublicType.INVALID}" 
                                href="#" 
                                @click="downloadSalesCatalog"
                            >
                                <i class="bi bi-file-earmark-ruled"></i> カタログ図
                            </a>
                        </li>
                        <li>
                            <a 
                                class="dropdown-item" 
                                :class="{'disabled': revision || product_group.public_type === PublicType.INVALID}" 
                                href="#" 
                                @click="downloadSalesStandard"
                            >
                                <i class="bi bi-file-earmark-ruled"></i> 規格図
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="table-scrollable">
                <table class="table table-bordered nowrap">
                    <thead>
                        <tr>
                            <th class="table-secondary text-center">製品コード</th>
                            <th class="table-secondary text-center" v-for="header of basic_headers" :key="header">
                                {{ header.label }}
                            </th>
                            <th class="table-info text-center" v-for="header of catalog_spec_headers" :key="header">
                                {{ header.label }} <i class="bi bi-book-fill text-info"></i>
                            </th>
                            <th class="table-warning text-center" v-for="header of standard_spec_headers" :key="header">
                                {{ header.label }} <i class="bi bi-layers-fill text-warning"></i>
                            </th>
                            <th class="table-success text-center" v-for="header of production_spec_headers" :key="header">
                                {{ header.label }} <i class="bi bi-lock-fill text-danger"></i>
                            </th>
                            <th class="table-secondary text-center" v-for="header of price_headers" :key="header">
                                {{ header.label }}
                            </th>
                            <th class="table-secondary text-center" v-for="header of product_retailer_headers" :key="header">
                                {{ header.__retailer_name }} {{ header.label }}
                            </th>
                            <th class="table-secondary text-center">製品説明</th>
                            <th class="table-secondary text-center" v-if="$store.getters['auth/canViewProduction']()">製作図</th>
                        </tr>
                    </thead>
                    <tbody v-if="!loading && is_loaded">
                        <tr v-for="product of browsing_subgroup.products" :key="product.code">
                            <td>
                                {{ product_group.product_group_code }}-<span class="fw-bold">{{ product.product_code }}</span>
                                <!-- 公開区分 -->
                                <span
                                    class="popup badge ms-1 me-1"
                                    :class="{
                                        'bg-dark': product.public_type === PublicType.EXTERNAL_PUBLIC,
                                        'bg-info': product.public_type === PublicType.IN_COMPANY_PUBLIC,
                                        'bg-danger': product.public_type === PublicType.INVALID,
                                    }"
                                >
                                    {{ PublicType.getShort(product.public_type) }}
                                    <span class="text-secondary bg-light rounded-3 shadow-sm p-1">{{ PublicType.get(product.public_type) }}</span>
                                </span>
                                <!-- ステータス -->
                                <span
                                    v-if="product.availability != Availability.NORMAL"
                                    class="popup badge me-1"
                                    :class="{
                                        'bg-info': product.availability === Availability.TEMPORARY_INFO,
                                        'bg-warning': product.availability === Availability.TEMPORARY_PAUSE,
                                        'bg-danger': product.availability === Availability.OUT_OF_PRINT,
                                    }"
                                >
                                    {{ Availability.getShort(product.availability) }}
                                    <span class="text-secondary bg-light rounded-3 shadow-sm p-1">{{ Availability.get(product.availability) }}</span>
                                </span>
                                <!-- 取引停止 -->
                                <template v-if="product.pending_date">
                                    <span class="popup badge bg-danger me-3">
                                        取引停止
                                        <span class="text-secondary bg-light rounded-3 shadow-sm p-1">取引停止日: {{ product.pending_date_display }}</span>
                                    </span>
                                </template>
                                <div v-if="product.product_id">
                                    <small class="sid-small"><small>SID: </small>{{ product.product_id }}</small>
                                </div>
                            </td>
                            <td v-for="header of basic_headers" :key="header" class="text-center">
                                {{ product.getValue(header.key) ?? '-' }}
                            </td>
                            <td v-for="header of catalog_spec_headers" :key="header" class="text-center">
                                {{ product.getValue(header.key) ?? '-' }}
                            </td>
                            <td v-for="header of standard_spec_headers" :key="header" class="text-center">
                                {{ product.getValue(header.key) ?? '-' }}
                            </td>
                            <td v-for="header of production_spec_headers" :key="header" class="text-center">
                                {{ product.getValue(header.key) ?? '-' }}
                            </td>
                            <td v-for="header of price_headers" :key="header" class="text-center">
                                {{ product.getValue(header.key, header.group) ?? '-' }}
                            </td>
                            <td v-for="header of product_retailer_headers" :key="header" class="text-center">
                                {{ product.getValue(header.key) ?? '-' }}
                            </td>
                            <td class="text-center">
                                <button-exec
                                    text=""
                                    icon="bi-file-earmark-text"
                                    color="btn-secondary"
                                    size="btn-sm"
                                    @click="openDescriptionModal(product)"
                                ></button-exec>
                            </td>
                            <!-- 製作図情報閲覧権限を持つ場合のみ -->
                            <td class="text-center" v-if="$store.getters['auth/canViewProduction']()">
                                <button-exec
                                    text=""
                                    icon="bi-file-earmark-lock"
                                    color="btn-danger"
                                    size="btn-sm"
                                    @click="openProductionModal(product)"
                                    :disabled="!product.production_drawings.length"
                                ></button-exec>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- 構成品 -->
            <div v-if="!loading && is_loaded && browsing_parent_component_products.length" class="mb-4">
                    <div class="d-flex align-items-center">
                        <span class="badge bg-light text-dark mb-1">
                            他製品の構成品に設定されている製品
                            <span class="text-secondary small">（公開中の情報に基づく）</span>
                        </span>
                    </div>
                    <table class="table table-bordered w-100 mb-0 small">
                        <thead>
                            <tr>
                                <th class="table-secondary text-center fw-normal p-0">製品</th>
                                <th class="table-secondary text-center fw-normal p-0">構成品</th>
                            </tr>
                        </thead>
                        <tbody class="border-top-0">
                            <tr v-for="component in browsing_parent_component_products" :key="component">
                                <td class="py-1">
                                    {{ component.product.joined_product_code }}（{{ component.product.product_group_name }}）
                                    <!-- 公開区分 -->
                                    <span
                                        class="popup badge ms-1 me-1"
                                        :class="{
                                            'bg-dark': component.product.public_type === PublicType.EXTERNAL_PUBLIC,
                                            'bg-info': component.product.public_type === PublicType.IN_COMPANY_PUBLIC,
                                            'bg-danger': component.product.public_type === PublicType.INVALID,
                                        }"
                                    >
                                        {{ PublicType.getShort(component.product.public_type) }}
                                        <span class="text-secondary bg-light rounded-3 shadow-sm p-1">{{ PublicType.get(component.product.public_type) }}</span>
                                    </span>
                                    <!-- ステータス -->
                                    <span
                                        v-if="component.product.availability != Availability.NORMAL"
                                        class="popup badge me-1"
                                        :class="{
                                            'bg-info': component.product.availability === Availability.TEMPORARY_INFO,
                                            'bg-warning': component.product.availability === Availability.TEMPORARY_PAUSE,
                                            'bg-danger': component.product.availability === Availability.OUT_OF_PRINT,
                                        }"
                                    >
                                        {{ Availability.getShort(component.product.availability) }}
                                        <span class="text-secondary bg-light rounded-3 shadow-sm p-1">{{ Availability.get(component.product.availability) }}</span>
                                    </span>
                                    <!-- 取引停止 -->
                                    <template v-if="component.product.pending_date">
                                        <span class="popup badge bg-danger me-3">
                                            取引停止
                                            <span class="text-secondary bg-light rounded-3 shadow-sm p-1">取引停止日: {{ component.product.pending_date_display }}</span>
                                        </span>
                                    </template>
                                </td>
                                <td class="py-1">
                                    {{ component.child_product.product_group_code }}-<span class="fw-bold">{{ component.child_product.product_code }}</span>
                                    <!-- 公開区分 -->
                                    <span
                                        class="popup badge ms-1 me-1"
                                        :class="{
                                            'bg-dark': component.child_product.public_type === PublicType.EXTERNAL_PUBLIC,
                                            'bg-info': component.child_product.public_type === PublicType.IN_COMPANY_PUBLIC,
                                            'bg-danger': component.child_product.public_type === PublicType.INVALID,
                                        }"
                                    >
                                        {{ PublicType.getShort(component.child_product.public_type) }}
                                        <span class="text-secondary bg-light rounded-3 shadow-sm p-1">{{ PublicType.get(component.child_product.public_type) }}</span>
                                    </span>
                                    <!-- ステータス -->
                                    <span
                                        v-if="component.child_product.availability != Availability.NORMAL"
                                        class="popup badge me-1"
                                        :class="{
                                            'bg-info': component.child_product.availability === Availability.TEMPORARY_INFO,
                                            'bg-warning': component.child_product.availability === Availability.TEMPORARY_PAUSE,
                                            'bg-danger': component.child_product.availability === Availability.OUT_OF_PRINT,
                                        }"
                                    >
                                        {{ Availability.getShort(component.child_product.availability) }}
                                        <span class="text-secondary bg-light rounded-3 shadow-sm p-1">{{ Availability.get(component.child_product.availability) }}</span>
                                    </span>
                                    <!-- 取引停止 -->
                                    <template v-if="component.child_product.pending_date">
                                        <span class="popup badge bg-danger me-3">
                                            取引停止
                                            <span class="text-secondary bg-light rounded-3 shadow-sm p-1">取引停止日: {{ component.child_product.pending_date_display }}</span>
                                        </span>
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        </section>

        <section class="section">
            <div class="mb-3" v-if="product_group.description || product_group.description_en">
                <div class="d-flex justify-content-between align-items-center mb-1">
                    <span class="badge bg-light text-dark">説明文</span>
                    <div class="btn-group" role="group">
                        <label class="btn btn-outline-secondary btn-left" :class="{'bg-secondary text-white': mode_lang === 'jp'}">
                            <input type="radio" class="btn-check" autocomplete="off" @click="changeModeJp()">
                        JP</label>
                        <label class="btn btn-outline-secondary btn-right" :class="{'bg-secondary text-white': mode_lang === 'en'}">
                            <input type="radio" class="btn-check" autocomplete="off" @click="changeModeEn()">
                            EN
                        </label>
                    </div>
                </div>
                <p v-if="mode_lang == 'jp'" class="border rounded bg-light p-2 view-textarea">
                    {{ product_group.description }}
                </p>
                <p v-if="mode_lang == 'en'" class="border rounded bg-light p-2 view-textarea">
                    {{ product_group.description_en }}
                </p>
            </div>

            <div class="mb-3" v-if="product_group.private_note">
                <span class="badge bg-light text-dark mb-1">社内備考</span>
                <p class="border rounded bg-light p-2 view-textarea">
                    {{ product_group.private_note }}
                </p>
            </div>

            <div class="mb-3" v-if="product_group.field_1 || product_group.field_2 || product_group.field_3">
                <span class="badge bg-light text-dark mb-1 me-2">その他</span>
                <span class="border rounded bg-light p-1 view-textarea small me-2" v-if="product_group.field_1">
                    <span class="badge bg-white text-secondary me-1">項目1</span>{{ product_group.field_1 }}
                </span>
                <span class="border rounded bg-light p-1 view-textarea small me-2" v-if="product_group.field_2">
                    <span class="badge bg-white text-secondary me-1">項目2</span>{{ product_group.field_2 }}
                </span>
                <span class="border rounded bg-light p-1 view-textarea small me-2" v-if="product_group.field_3">
                    <span class="badge bg-white text-secondary me-1">項目3</span>{{ product_group.field_3 }}
                </span>
            </div>

            <!-- 動画 -->
            <div v-if="!loading && is_loaded && product_group.movies.length" class="mb-4">
                <span class="badge bg-light text-dark mb-1">動画</span>
                <div class="">
                    <span v-for="movie in product_group.movies" :key="movie">
                        <a :href="movie.movie_url" class="border rounded bg-light p-1 view-textarea text-decoration-none small me-2" target="_blank">
                            {{ movie.movie_name }} <i class="bi bi-reply rotate"></i>
                        </a>
                    </span>
                </div>
            </div>
            <span class="badge bg-light text-dark mb-1">カタログ掲載</span>
            <div class="mb-3 d-flex" v-if="isShowProductGroupCatalogs || showProductSubGroupCatalogs.length">
                <!-- <span class="fw-bold small px-3 bg-light text-dark text-center rounded d-flex align-items-center nowrap small me-2">
                    カタログ<br>掲載
                </span> -->
                <div class="flex-grow-1">
                    <!-- 製品グループのカタログ掲載情報 -->
                    <div class="mb-1">
                        <table class="table table-bordered w-100 small mb-0" v-if="isShowProductGroupCatalogs" >
                            <thead>
                                <tr>
                                    <th class="table-secondary text-center fw-normal p-0">掲載カタログ名</th>
                                    <th class="table-secondary text-center fw-normal p-0 nowrap">金額掲載</th>
                                    <th class="table-secondary text-center fw-normal p-0">掲載ページ</th>
                                    <th class="table-secondary text-center fw-normal p-0">カタログURL</th>
                                </tr>
                            </thead>
                            <tbody class="border-top-0">
                                <tr v-for="catalog_page in product_group.catalog_pages" :key="catalog_page">
                                    <td class="text-center py-1">{{ catalog_page.catalog.catalog_name }}</td>
                                    <td class="text-center py-1">{{ IsPriceOnCatalog.getDisplayValues(product_group.is_price_on_catalog) }}</td>
                                    <td class="text-center nowrap py-1">{{ catalog_page.page_display }}</td>
                                    <td class="text-center wrap py-1">
                                        <template v-if="catalog_page.catalog_url">
                                            <a :href="catalog_page.catalog_url" target="_blank" class="text-decoration-none">
                                                {{ catalog_page.catalog_url }} <i class="bi bi-reply rotate"></i>
                                            </a>
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-else class="small d-inline-block mb-0">
                            <i class="bi bi-info-circle-fill"></i> {{ product_group.is_on_catalog === IsOnCatalog.YES ? 'カタログ掲載あり' : 'カタログ掲載なし' }}（{{ product_group.is_price_on_catalog === IsPriceOnCatalog.YES ? 'カタログ金額掲載あり' : 'カタログ金額掲載なし' }}）
                        </div>

                        <div class="mt-1 d-flex align-items-end" v-if="product_group.merged_product_group">
                            <span class="badge bg-light border text-muted me-2">合成 (親)</span>
                            <router-link :to="{name: 'ProductGroupView', params: {id: product_group.merged_product_group.product_group_id}}" class="text-decoration-none d-flex" target="_blank">
                                <span class="badge bg-success d-flex align-items-center me-1">{{ product_group.merged_product_group.product_group_code }}</span> {{ product_group.merged_product_group.product_group_name }} {{ product_group.merged_product_group.option_name }} <i class="bi bi-reply rotate"></i>
                            </router-link>
                        </div>
                    </div>
                    <!-- 各製品サブグループのカタログ掲載情報 -->
                    <div class="flex-grow-1 mt-1" v-for="(subgroup, index) in product_group.subgroups" :key="subgroup">
                        <!-- サブグループが１つの場合は、親の製品グループのカタログ掲載設定のみなので（サブグループにはカタログ掲載設定がない） -->
                        <div class="d-flex" v-if="product_group.subgroups.length > 1">
                            <!-- サブグループ名 -->
                            <div class="col-1"></div>
                            <div class="col-3 d-flex align-items-center">
                                <span class="badge bg-light text-dark pre-wrap w-100 mb-1 me-2">{{ subgroup.product_subgroup_name }}</span>
                            </div>
                            <!-- <span class="col-4 fw-bold small px-3 py-2 bg-light text-dark small text-center rounded d-flex justify-content-center align-items-center me-2">
                                {{ subgroup.product_subgroup_name }}
                            </span> -->
                            <div class="flex-grow-1">
                                <table class="table table-bordered w-100 small mb-0" v-if="showProductSubGroupCatalogs.includes(index)">
                                    <thead>
                                        <tr>
                                            <th class="table-secondary text-center fw-normal p-0">掲載カタログ名</th>
                                            <th class="table-secondary text-center fw-normal p-0 nowrap">金額掲載</th>
                                            <th class="table-secondary text-center fw-normal p-0">掲載ページ</th>
                                            <th class="table-secondary text-center fw-normal p-0">カタログURL</th>
                                        </tr>
                                    </thead>
                                    <tbody class="border-top-0">
                                        <tr v-for="catalog_page in subgroup.catalog_pages" :key="catalog_page">
                                            <td class="text-center py-1">{{ catalog_page.catalog.catalog_name }}</td>
                                            <td class="text-center py-1">{{ IsPriceOnCatalog.getDisplayValues(subgroup.is_price_on_catalog) }}</td>
                                            <td class="text-center py-1">{{ catalog_page.page_display }}</td>
                                            <td class="text-center py-1">
                                                <a :href="catalog_page.catalog_url" target="_blank" class="text-decoration-none" v-if="catalog_page.catalog_url">
                                                    {{ catalog_page.catalog_url }} <i class="bi bi-reply rotate"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-else class="small d-inline-block mb-0">
                                    <i class="bi bi-info-circle-fill"></i> {{ subgroup.is_on_catalog === IsOnCatalog.YES ? 'カタログ掲載あり' : 'カタログ掲載なし' }}（{{ subgroup.is_price_on_catalog === IsPriceOnCatalog.YES ? 'カタログ金額掲載あり' : 'カタログ金額掲載なし' }}）
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="small d-inline-block">
                <i class="bi bi-info-circle-fill"></i> カタログ掲載なし（{{ product_group.is_price_on_catalog === IsPriceOnCatalog.YES ? 'カタログ金額掲載あり' : 'カタログ金額掲載なし' }}）
            </div>

            <!-- 規格変更管理番号 -->
            <div class="mb-3" v-if="!loading && is_loaded && joined_pending_workflows">
                <span class="badge bg-light text-dark mb-1 me-2">規格変更管理番号</span>
                <span class="border rounded bg-warning p-1 view-textarea small me-2">
                    {{ joined_pending_workflows }}
                </span>
            </div>
        </section>

        <section class="section">
            <h2 class="section-title">共有メモ</h2>

            <ul class="list-group mx-5">
                <template v-for="note, index in this.product_group.notes" :key="note">
                    <li class="list-group-item">
                        <div>{{ note.content }}</div>
                        <div class="text-end text-secondary">
                            <a
                                v-if="login_user.user_id === note.user.user_id || $store.getters['auth/canEditAny']()"
                                class="text-primary hover p-1"
                                @click="$refs.confirm_remove_product_note.show(); setTargetIndex(index);"
                            ><i class="bi bi-trash"></i></a>
                            {{ note.note_date_display }} {{ note.user.user_name }}
                        </div>
                    </li>
                </template>
                <li class="list-group-item">
                    <div class="d-flex align-items-end">
                        <form-textarea
                            v-model="additional_content"
                            row="'3'"
                        ></form-textarea>
                        <button class="btn btn-outline-primary nowrap ms-3" @click="saveNote()">登録</button>
                    </div>
                </li>
            </ul>
        </section>

    </template>

    <template v-if="!loading && is_loaded">
        <!-- 画像拡大モーダル -->
        <template v-if="zoomed_image_modal">
            <div class="modal fade show d-block large-z-index" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content image-modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title"><span class="badge bg-light text-dark">{{ target_image.__category_name }}</span> {{ target_image.file_label }}</h5>
                            <button type="button" class="btn-close" @click="closeZoomedImageModal"></button>
                        </div>
                        <div class="modal-body zoom-body d-flex align-items-center justify-content-center">
                            <!-- PDFの場合 -->
                            <a :href="target_image.file_url + '?access_token=' + this.$store.state.auth.access_token" target="__blank" v-if="target_image.mime_type.indexOf('pdf') != -1" class="pdf-wrap">
                                <img :src="target_image.thumbnail_url + '?access_token=' + this.$store.state.auth.access_token" class="image-modal-image min-vw-25">
                            </a>
                            <!-- それ以外の場合 -->
                            <img :src="target_image.file_url + '?access_token=' + this.$store.state.auth.access_token" class="image-modal-image mw-100 mh-100" v-else>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <button-exec
                                text="前へ"
                                icon=""
                                color="btn-secondary"
                                @click="prevZoomedImage()"
                            ></button-exec>
                            <span>{{ zoomed_index + 1 }}/{{ zoomed_images.length }}</span>
                            <button-exec
                                text="次へ"
                                icon=""
                                color="btn-secondary"
                                @click="nextZoomedImage()"
                            ></button-exec>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </template>

        <!-- ユーザ製品表示項目モーダル -->
        <template v-if="spec_values_modal">
            <div class="modal fade show d-block" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">表示項目設定</h5>
                            <button type="button" class="btn-close" @click="spec_values_modal = false"></button>
                        </div>

                        <div class="modal-body">
                            <span class="badge bg-light text-dark">基本情報</span>
                            <div class="d-flex flex-wrap mb-3">
                                <template v-for="basic in preference_list.basics" :key="basic">
                                    <!-- [原価][作業時間][R][Y][G]は、製品情報閲覧権限を持つ場合のみ表示 -->
                                    <template v-if="true === this.AuthProductionViewLists.includes(basic.key) && $store.getters['auth/canViewProduction']()">
                                        <label class="form-check-inline text-danger">
                                            <input
                                                type="checkbox"
                                                :value="basic.key"
                                                v-model="selected_preference_list"
                                                class="form-check-input text-c"
                                            >
                                            <span class="form-check-label">{{basic.label}}</span>
                                        </label>
                                    </template>
                                    <template v-else-if="false === this.AuthProductionViewLists.includes(basic.key)">
                                        <label class="form-check-inline">
                                            <input
                                                type="checkbox"
                                                :value="basic.key"
                                                v-model="selected_preference_list"
                                                class="form-check-input"
                                            >
                                            <span class="form-check-label">{{basic.label}}</span>
                                        </label>
                                    </template>
                                </template>
                            </div>

                            <span class="badge bg-info-light text-dark">カタログ用規格 <i class="bi bi-book-fill text-info fs-6"></i></span>
                            <div class="mb-3 d-flex flex-wrap">
                                <template v-for="catalog_spec_label in preference_list.catalog_spec_labels" :key="catalog_spec_label">
                                    <label class="form-check-inline">
                                        <input
                                            type="checkbox"
                                            v-model="selected_catalog_spec_label_list"
                                            :value="catalog_spec_label.key"
                                            class="form-check-input"
                                        >
                                        <span class="form-check-label">{{catalog_spec_label.label}}</span>
                                    </label>
                                </template>
                            </div>

                            <span class="badge bg-warning-light text-dark">規格図用規格 <i class="bi bi-layers-fill text-warning fs-6"></i></span>
                            <div class="mb-3 d-flex flex-wrap">
                                <template v-for="standard_spec_label in preference_list.standard_spec_labels" :key="standard_spec_label">
                                    <label class="form-check-inline">
                                        <input
                                            type="checkbox"
                                            v-model="selected_standard_spec_label_list"
                                            :value="standard_spec_label.key"
                                            class="form-check-input"
                                        >
                                        <span class="form-check-label">{{standard_spec_label.label}}</span>
                                    </label>
                                </template>
                            </div>

                            <!-- 製品情報閲覧権限を持つ場合のみ -->
                            <template v-if="$store.getters['auth/canViewProduction']()">
                                <span class="badge bg-success-light text-dark">製作図用規格 <i class="bi bi-lock-fill text-danger fs-6"></i></span>
                                <div class="mb-3 d-flex flex-wrap">
                                    <template v-for="production_spec_label in preference_list.production_spec_labels" :key="production_spec_label">
                                        <label class="form-check-inline text-danger">
                                            <input
                                                type="checkbox"
                                                v-model="selected_production_spec_label_list"
                                                :value="production_spec_label.key"
                                                class="form-check-input"
                                            >
                                            <span class="form-check-label">{{production_spec_label.label}}</span>
                                        </label>
                                    </template>
                                </div>
                            </template>

                            <span class="badge bg-light text-dark">価格情報</span>
                            <div class="d-flex flex-wrap mb-3">
                                <template v-for="price in preference_list.prices" :key="price">
                                    <label class="form-check-inline">
                                        <label class="form-check-inline">
                                            <input
                                                type="checkbox"
                                                :value="price.key"
                                                v-model="selected_preference_list"
                                                class="form-check-input"
                                            >
                                            <span class="form-check-label">{{price.label}}</span>
                                        </label>
                                    </label>
                                </template>
                            </div>

                            <span class="badge bg-light text-dark mb-1">商社別情報</span>
                            <div class="mb-3 d-flex flex-wrap">
                                <template v-for="product_retailer, index in preference_list.retailers" :key="product_retailer">
                                    <div v-if="index != 0 && product_retailer.__the_first" class="force-wrap"></div><!-- 商社始まりで１つ目以外 -->
                                    <div v-if="product_retailer.__the_first" class="badge bg-light text-dark retailer-name mb-1 me-2">{{ product_retailer.__retailer_name }}</div><!-- 商社始まり -->
                                    <div>
                                        <label class="form-check-inline">
                                            <input
                                                type="checkbox"
                                                :value="product_retailer.key"
                                                v-model="selected_product_retailer_list"
                                                class="form-check-input"
                                            >
                                            <span class="form-check-label">{{product_retailer.label}}</span>
                                        </label>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button-exec
                                text="完了"
                                icon=""
                                color="btn-secondary"
                                @click="spec_values_modal = false"
                            ></button-exec>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </template>

        <!-- 説明情報表示モーダル -->
        <template v-if="description_modal">
            <div class="modal fade show d-block" tabindex="-1">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                <span class="badge bg-light text-dark">製品情報</span>
                                {{ product_group.product_group_code }}-{{ target_product.product_code }}
                            </h5>
                            <button type="button" class="btn-close" @click="closeDescriptionModal()"></button>
                        </div>
                        <div class="modal-body">
                            <!-- 全製品情報画像LightBox -->
                            <div v-if="target_product.images.length" class="mb-3">
                                <span class="badge bg-light text-dark mb-1">画像</span>
                                <div class="row">
                                    <template v-for="image, index in target_product.images" :key="image">
                                        <a class="col-6 image-link" @click="openZoomedAllImageModal(index, target_product.images)" role="button">
                                            <!-- <span class="badge bg-light text-dark py-1">{{ image.__category_name }}</span> -->
                                            <div class="img">
                                                <img :src="image.thumbnail_url + '?access_token=' + this.$store.state.auth.access_token" :alt="image.file_name" style="max-width:100%;">
                                            </div>
                                            <div class="file-name">
                                                <span v-if="image.file_label">{{ image.file_label }}</span>
                                                <span>（{{ image.file_name }}）</span>
                                            </div>
                                        </a>
                                    </template>
                                </div>
                            </div>
                            <!-- 説明文 -->
                            <template v-if="target_product.description || target_product.description_en">
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <span class="badge bg-light text-dark">説明文</span>
                                    <div class="btn-group" role="group">
                                        <label class="btn btn-outline-secondary btn-left" :class="{'bg-secondary text-white': mode_lang === 'jp'}">
                                            <input type="radio" class="btn-check" autocomplete="off" @click="changeModeJp()">
                                            JP
                                        </label>
                                        <label class="btn btn-outline-secondary btn-right" :class="{'bg-secondary text-white': mode_lang === 'en'}">
                                            <input type="radio" class="btn-check" autocomplete="off" @click="changeModeEn()">
                                            EN
                                        </label>
                                    </div>
                                </div>
                                <p v-if="mode_lang == 'jp'" class="border rounded bg-light p-2 view-textarea">
                                    {{ target_product.description }}
                                </p>
                                <p v-if="mode_lang == 'en'" class="border rounded bg-light p-2 view-textarea">
                                    {{ target_product.description_en }}
                                </p>
                            </template>
                            <!-- 注釈 -->
                            <template v-if="target_product.note || target_product.note_en">
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <span class="badge bg-light text-dark">注釈</span>
                                    <div class="btn-group" role="group">
                                        <label class="btn btn-outline-secondary btn-left" :class="{'bg-secondary text-white': mode_lang === 'jp'}">
                                            <input type="radio" class="btn-check" autocomplete="off" @click="changeModeJp()">
                                            JP
                                        </label>
                                        <label class="btn btn-outline-secondary btn-left" :class="{'bg-secondary text-white': mode_lang === 'en'}">
                                            <input type="radio" class="btn-check" autocomplete="off" @click="changeModeEn()">
                                            EN
                                        </label>
                                    </div>
                                </div>
                                <p v-if="mode_lang == 'jp'" class="border rounded bg-light p-2 view-textarea">
                                    {{ target_product.note }}
                                </p>
                                <p v-if="mode_lang == 'en'" class="border rounded bg-light p-2 view-textarea">
                                    {{ target_product.note_en }}
                                </p>
                            </template>
                            <!-- 構成品 -->
                            <template  v-if="target_product.components.length">
                                <span class="badge bg-light text-dark mb-1">構成品</span>
                                <table class="table table-bordered nowrap">
                                    <thead>
                                        <tr>
                                            <th class="table-secondary text-center">番号</th>
                                            <th class="table-secondary text-center">製品コード</th>
                                            <th class="table-secondary text-center">製品名</th>
                                            <th class="table-secondary text-center">規格</th>
                                            <th class="table-secondary text-center">価格</th>
                                            <th class="table-secondary text-center">入数</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="component of target_product.components" :key="component">
                                            <td class="text-end">{{ component.sequence }}</td>
                                            <td class="text-center">
                                                <router-link :to="{name: 'ProductGroupView', params: {id: component.child_product.product_group_id}}" target="_blank" class="text-decoration-none">
                                                    {{ component.child_product.joined_product_code }} <i class="bi bi-reply rotate"></i>
                                                </router-link>
                                            </td>
                                            <td class="text-center">{{ component.child_product.product_group_name }}</td>
                                            <td class="text-center">{{ component.child_product.joined_standard_spec_values }}</td>
                                            <td class="text-end">{{ component.child_product.regular_price_display }}</td>
                                            <td class="text-end">{{ component.component_count_display }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </template>

        <!-- 製作図表示モーダル -->
        <template v-if="production_modal">
            <div class="modal fade show d-block" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                <span class="badge bg-light text-dark">製作図情報</span>
                                {{ product_group.product_group_code }}-{{ target_product.product_code }}
                            </h5>
                            <button type="button" class="btn-close" @click="closeProductionModal()"></button>
                        </div>
                        <div class="modal-body">
                            <!-- 製作図画像 -->
                            <div v-if="target_product.production_drawings.length" class="mb-3">
                                <span class="badge bg-light text-dark mb-1">製作図</span>
                                <div class="row">
                                    <template v-for="image, index in target_product.production_drawings" :key="image">
                                        <a class="col-6 image-link" @click="openZoomedAllImageModal(index, target_product.production_drawings)" role="button">
                                            <!-- <span class="badge bg-light text-dark py-1">{{ image.__category_name }}</span> -->
                                            <div class="img">
                                                <img :src="image.thumbnail_url + '?access_token=' + this.$store.state.auth.access_token" :alt="image.file_name" style="max-width:100%;">
                                            </div>
                                            <div class="file-name">
                                                <span v-if="image.file_label">{{ image.file_label }}</span>
                                                <span>（{{ image.file_name }}）</span>
                                            </div>
                                        </a>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </template>
    </template>

    <confirm-dialog ref="confirm_remove_product_note" @ok="removeNote()" @cancel="unsetTargetIndex()">
        <p>共有メモを削除してよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import {downloadExcel} from '@/utilities/download';
import PreferenceControlService from '@/models/services/preference-control-service';
import ProductGroup from '@/models/entities/product-group';
import ParentComponentProduct from '@/models/entities/parent-component-product';
import ProductNote from '@/models/entities/product-note';
import User from '@/models/entities/user';
import Retailer from '@/models/entities/retailer';
import UserPreference from '@/models/entities/user-preference';
import ApprovedStatus from '@/models/enums/product-group-workflow/approved-status';
import Availability from '@/models/enums/availability';
import PublishedStatus from '@/models/enums/published-status';
import IsOnCatalog from '@/models/enums/is-on-catalog';
import IsPriceOnCatalog from '@/models/enums/is-price-on-catalog';
import RevisionStatus from '@/models/enums/revision-status';
import PreferenceType from '@/models/enums/preference-type';
import PublicType from '@/models/enums/public-type';
import SupplyType from '@/models/enums/supply-type';
import PageTitle from '@/components/PageTitle.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonBack from '@/components/buttons/ButtonBack';
import ButtonGoUpdate from '@/components/buttons/ButtonGoUpdate.vue';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import FormTextarea from '@/components/forms/FormTextarea';

export default {
    name: 'ProductGroupView',
    components: {
        InlineLoader,
        PageTitle,
        ButtonExec,
        ButtonBack,
        ButtonGoUpdate,
        ConfirmDialog,
        FormTextarea,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage',
    ],
    data() {
        return {
            //部分ローダー
            loading: 0,

            //読込完了フラグ
            is_loaded: false,

            //現在の製品グループID
            product_group_id: parseInt(this.$route.params.id, 10),
            //リビジョン番号(リビジョンでアクセス)
            revision: null,

            //説明の言語
            mode_lang: 'jp',

            //製品グループ（製品リビジョングループを兼ねる）
            product_group: null, //nullで判定

            //商社
            retailers: [],

            //対象製品グループに紐づく製品の構成品親製品
            parent_component_products: [],

            //ユーザ製品表示項目
            preference: new UserPreference(),

            //閲覧中のサブグループ
            browsing_subgroup: null, //nullで判定
            browsing_subgroup_index: 0,

            //ログイン中ユーザ
            login_user: new User(this.$store.state.auth),

            //追加共有メモ
            additional_content: null,

            //コンファーム用
            target_index: null,

            //ユーザ製品表示項目一覧
            preference_list: {
                basics: [],
                prices: [],
                catalog_spec_labels: [],
                standard_spec_labels: [],
                production_spec_labels: [],
                retailers: []
            },

            //選択された製品表示項目（ただの配列）
            selected_preference_list: [],
            selected_catalog_spec_label_list: [],
            selected_standard_spec_label_list: [],
            selected_production_spec_label_list: [],
            selected_product_retailer_list: [],

            //enums
            Availability: Availability,
            ApprovedStatus: ApprovedStatus,
            PublishedStatus: PublishedStatus,
            PublicType: PublicType,
            SupplyType: SupplyType,
            IsOnCatalog: IsOnCatalog,
            IsPriceOnCatalog: IsPriceOnCatalog,
            PreferenceType: PreferenceType,

            //製作情報閲覧権限表示リスト（製作情報閲覧権限を持たない場合、[原価][作業時間][R][Y][G]は非表示）
            AuthProductionViewLists: [
                'production_cost', //原価
                'production_hours', //作業時間
                'product_buffer_red', //R
                'product_buffer_yellow', //Y
                'product_buffer_green' //G
            ],

            //画像拡大modal
            lightbox_product_group_all_images: [],
            lightbox_product_subgroup_all_images: [],
            zoomed_image_modal: false,
            zoomed_images: [],
            zoomed_index: null,
            target_image: {},

            //表示項目modal
            spec_values_modal: false,

            //製品説明modal
            description_modal: false,
            production_modal: false,
            target_product: {},
        }
    },
    mounted() {
        this.revision = this.$helper.isNud(this.$route.params.revision) ? null : parseInt(this.$route.params.revision, 10);
        this.fetchProductGroup();

        //自身を構成品に設定している製品を取得
        this.fetchParentComponentProducts();

        //表示項目設定用
        this.preference_list.basics = PreferenceControlService.getBasics();
        this.preference_list.prices = PreferenceControlService.getPrices();
    },
    computed: {
        //閲覧権限（公開アクセス）
        isBrowsable() {
            //編集ステータスが下書き または 公開区分が無効 の場合
            if (
                this.product_group.published_status === PublishedStatus.DRAFT
                || this.product_group.public_type === PublicType.INVALID
            ) {
                //かつ いずれの編集権限もなし
                if (!this.$store.getters['auth/canEditAny']()) {
                    return false;
                }
            }

            return true;
        },
        //閲覧権限（リビジョンアクセス）
        isBrowsableRevision() {
            //いずれの編集権限もなし かつ リビジョンステータスが未公開
            if (
                !this.$store.getters['auth/canEditAny']() //いずれかの編集権限
                && this.product_group.revision_status === RevisionStatus.PENDING
            ) {
                return false;
            }

            return true;
        },
        //サブグループがあるか
        hasSubgroups() {
            if (this.product_group.subgroups.length > 1) {
                return true;
            }

            return false;
        },
        //表で使う選択された基本情報をオブジェクトに戻してヘッダーにする
        basic_headers() {
            let basic_headers = [];
            for (let basic of this.preference_list.basics) {
                if (this.selected_preference_list.includes(basic.key)) {
                    basic_headers.push(basic);
                }
            }
            return basic_headers;
        },
        //表で使う選択された価格情報をオブジェクトに戻してヘッダーにする
        price_headers() {
            let price_headers = [];
            for (let price of this.preference_list.prices) {
                if (this.selected_preference_list.includes(price.key)) {
                    price_headers.push(price);
                }
            }
            return price_headers;
        },
        //表で使う選択されたカタログ図規格項目をオブジェクトに戻してヘッダーにする
        catalog_spec_headers() {
            let catalog_spec_headers = [];
            for (let catalog_spec_label of this.preference_list.catalog_spec_labels) {
                if (this.selected_catalog_spec_label_list.includes(catalog_spec_label.key)) {
                    catalog_spec_headers.push(catalog_spec_label);
                }
            }
            return catalog_spec_headers;
        },
        //表で使う選択された規格図規格項目をオブジェクトに戻してヘッダーにする
        standard_spec_headers() {
            let standard_spec_headers = [];
            for (let standard_spec_label of this.preference_list.standard_spec_labels) {
                if (this.selected_standard_spec_label_list.includes(standard_spec_label.key)) {
                    standard_spec_headers.push(standard_spec_label);
                }
            }

            return standard_spec_headers;
        },
        //表で使う選択された製作図規格項目をオブジェクトに戻してヘッダーにする
        production_spec_headers() {
            let production_spec_headers = [];
            for (let production_spec_label of this.preference_list.production_spec_labels) {
                if (this.selected_production_spec_label_list.includes(production_spec_label.key)) {
                    production_spec_headers.push(production_spec_label);
                }
            }

            return production_spec_headers;
        },
        //表で使う選択された商社別規格項目をオブジェクトに戻してヘッダーにする
        product_retailer_headers() {
            let product_retailer_headers = [];
            for (let product_retailer of this.preference_list.retailers) {
                if (this.selected_product_retailer_list.includes(product_retailer.key)) {
                    product_retailer_headers.push(product_retailer);
                }
            }

            return product_retailer_headers;
        },
        //自身を構成品に設定している製品をセット（必ずサブグループがSETされてから実行）
        browsing_parent_component_products () {
            //閲覧中のサブグループの他製品の構成品に設定されている製品をセット
            let filtered = this.parent_component_products.filter((row) => {
                return row.child_product.product_subgroup_id === this.browsing_subgroup.product_subgroup_id;
            });

            return filtered;
        },
        //未完の規格変更管理番号JOIN
        joined_pending_workflows() {
            let pending_workflows = this.product_group.workflows.filter((workflow) => {
                return workflow.approved_status === ApprovedStatus.PENDING;
            });
            let pending_workflows_numbers = [];
            pending_workflows.forEach((workflow) => {
                pending_workflows_numbers.push(workflow.workflow_number);
            });
            return pending_workflows_numbers.join(' / ');
        },
        //製品グループのカタログ掲載情報を表示するか（掲載フラグON & 掲載情報rowが存在する）
        isShowProductGroupCatalogs() {
            return this.product_group.is_on_catalog === IsOnCatalog.YES && this.product_group.catalog_pages.length
        },
        //各製品サブグループのカタログ掲載情報を表示する状態か（各サブグループの 掲載フラグON & 掲載情報rowが存在する）
        showProductSubGroupCatalogs() {
            let show_catalogs = [];
            this.product_group.subgroups.forEach(function (subgroup, index) {
                if (subgroup.is_on_catalog === IsOnCatalog.YES && subgroup.catalog_pages.length) {
                    show_catalogs.push(index);
                }
            });

            return show_catalogs;

            // 各製品サブグループのカタログ掲載情報を表示する状態か
            // let show_catalogs = this.product_group.subgroups.some(function (subgroup) {
            //     if (subgroup.is_on_catalog === IsOnCatalog.YES && subgroup.catalog_pages.length) {
            //         return true;
            //     }
            // });
            // if (show_catalogs) {
            //     return true;
            // } else {
            //     return false;
            // }
        },
    },
    methods: {
        //製品グループマスタ[公開版/リビジョン版]を取得
        fetchProductGroup() {
            this.loading++;
            let url = null;

            //公開版で開いた場合
            if (!this.revision) {
                url = `/product-group/${this.product_group_id}`;
            //リビジョン版で開いた場合
            } else  {
                url = `/product-group/${this.product_group_id}/revision/${this.revision}`;
            }

            this.$http.get(url)
            .then(response => {
                this.product_group = new ProductGroup(response.data);
                if (!response.data) {
                    this.showErrorMessage('該当データがありません');
                    this.$router.push({name: 'ProductGroupList'});
                    return;
                }
                //画像・サブグループをセット
                this.setChildren();
            })
            .finally(() => {
                this.loading--;
                this.classifyRevision();
            });
        },
        //公開版かリビジョン版か分岐
        classifyRevision() {
            //公開版で開いた場合
            if (!this.revision) {
                //閲覧チェック
                if (!this.isBrowsable) {
                    this.errorBrowsableMessage();
                    return;
                }
            //リビジョン版で開いた場合
            } else  {
                //閲覧チェック
                if (!this.isBrowsableRevision) {
                    this.errorBrowsableMessage();
                    return;
                }
            }
        },
        //閲覧権限NGメッセージ表示
        errorBrowsableMessage() {
            this.showErrorMessage('閲覧権限がありません');
            this.$router.push({name: 'ProductGroupList'});
        },
        //対象製品グループに紐づく製品の構成品親製品[公開版/リビジョン版]を取得（自身を構成品に設定している製品を取得）
        fetchParentComponentProducts () {
            this.loading++;
            //公開版でもリビジョン版でも
            this.$http.get(`/product-group/${this.product_group_id}/parent-component-products`)
            .then(response => {
                if (response.data.length > 0) {
                    for (let row of response.data) {
                        this.parent_component_products.push(new ParentComponentProduct(row));
                    }
                }
            })
            .finally(() => {
                this.loading--;
                this.is_loaded = true; //表示してよいフラグ
            });
        },
        //表示する全画像・サブグループ等のセット
        setChildren() {
            //表示する製品サブグループをセット
            if (this.product_group.subgroups.length) {
                this.setBrowsingSubgroup(this.product_group.subgroups[0]);
            }

            //製品グループの全画像類を配列化
            this.lightbox_product_group_all_images = [];
            this.product_group.images.forEach((image) => {
                image.__category_name = '画像'; //__付キーはFront表示用
                this.lightbox_product_group_all_images.push(image);
            });
            this.product_group.catalog_drawings.forEach((image) => {
                image.__category_name = 'カタログ図';
                this.lightbox_product_group_all_images.push(image);
            });
            this.product_group.standard_drawings.forEach((image) => {
                image.__category_name = '規格図';
                this.lightbox_product_group_all_images.push(image);
            });
            this.product_group.flyers.forEach((image) => {
                image.__category_name = 'チラシ';
                this.lightbox_product_group_all_images.push(image);
            });
        },
        //閲覧中のサブグループをセット
        setBrowsingSubgroup(subgroup, index = 0){
            this.browsing_subgroup = subgroup;
            this.browsing_subgroup_index = index;

            //製品サブグループの全画像類を配列化
            this.lightbox_product_subgroup_all_images = [];
            subgroup.images.forEach((image) => {
                image.__category_name = '画像'; //__付キーはFront表示用
                this.lightbox_product_subgroup_all_images.push(image);
            });
            subgroup.catalog_drawings.forEach((image) => {
                image.__category_name = 'カタログ図';
                this.lightbox_product_subgroup_all_images.push(image);
            });
            subgroup.standard_drawings.forEach((image) => {
                image.__category_name = '規格図';
                this.lightbox_product_subgroup_all_images.push(image);
            });
            //製作図情報閲覧権限がある場合のみ
            if (this.$store.getters['auth/canViewProduction']()) {
                subgroup.production_drawings.forEach((image) => {
                    image.__category_name = '製作図';
                    this.lightbox_product_subgroup_all_images.push(image);
                });
            }

            //表示項目設定用
            this.generatePreferenceList(); //ユーザ製品表示項目・商社取得
        },
        //商社マスタを取得
        fetchRetailers() {
            this.loading++;

            this.$http.get(`/retailer`)
            .then(response => {
                this.retailers.splice(0);
                for (let row of response.data) {
                    this.retailers.push(new Retailer(row));
                }

                //表示項目モーダル用
                this.generateRetailerPreferenceList()
            })
            .finally(() => {
                this.loading--;
            });
        },
        //製品グループ全画像拡大モーダルを開く
        openZoomedAllImageModal(index, images) {
            this.zoomed_image_modal = true;
            this.zoomed_images = images;
            this.zoomed_index = index;
            this.target_image = images[index];
        },
        //画像拡大モーダルを閉じる
        closeZoomedImageModal() {
            this.zoomed_image_modal = false;
            // this.zoomed_images.splice(0);
            // this.zoomed_index = null;
            // this.target_image = {};
        },
        //前の画像へ
        prevZoomedImage() {
            this.target_image = {};
            if (this.zoomed_index > 0) {
                this.zoomed_index--;
                this.target_image = this.zoomed_images[this.zoomed_index];
            } else if (this.zoomed_index === 0) {
                this.zoomed_index = this.zoomed_images.length - 1;
                this.target_image = this.zoomed_images[this.zoomed_index];
            }
        },
        //次の画像へ
        nextZoomedImage() {
            this.target_image = {};
            if (this.zoomed_index < (this.zoomed_images.length - 1)) {
                this.zoomed_index++;
                this.target_image = this.zoomed_images[this.zoomed_index];
            } else if (this.zoomed_index === this.zoomed_images.length - 1) {
                this.zoomed_index = 0;
                this.target_image = this.zoomed_images[this.zoomed_index];
            }
        },
        // 今の日付を出力する(帳簿ダウンロードのファイル名用)
        DisplayNow() {
            let dt = new Date();
            let y = dt.getFullYear();
            let m = (dt.getMonth()+1).toString().padStart(2, "0");
            let d = dt.getDate().toString().padStart(2, "0");
            let h = dt.getHours().toString().padStart(2, "0");
            let i = dt.getMinutes().toString().padStart(2, "0");
            let s = dt.getSeconds().toString().padStart(2, "0");

            return `${y}${m}${d}_${h}${i}${s}`;
        },
        //カタログ図ダウンロード
        downloadSalesCatalog() {
            this.startScreenLoading();

            this.$http.get(`/product-group/${this.product_group_id}/document/catalog`, {responseType: "blob"})
            .then(response => {
                downloadExcel(response.data, `sales_catalog_${this.product_group.product_group_name}_${this.DisplayNow()}`);
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //規格図ダウンロード
        downloadSalesStandard() {
            this.startScreenLoading();

            this.$http.get(`/product-group/${this.product_group_id}/document/standard`, {responseType: "blob"})
            .then(response => {
                downloadExcel(response.data, `sales_standard_${this.product_group.product_group_name}_${this.DisplayNow()}`);
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //ユーザ設定の初期表示する製品表示項目取得
        generatePreferenceList() {
            this.loading++;

            this.$http.get(`/me/preference`)
            .then(response => {
                this.preference = new UserPreference(response.data);
                //ユーザ製品表示項目からいらないpropertyは削除
                delete this.preference['user_preference_id'];
                delete this.preference['user'];
                for (let key in this.preference) {
                    if (this.preference[key] === PreferenceType.SHOW) {
                        // 製作情報閲覧権限を持たない場合、[原価][作業時間][R][Y][G]は非表示
                        if (false === this.AuthProductionViewLists.includes(key) || (true === this.AuthProductionViewLists.includes(key) && this.$store.getters['auth/canViewProduction']())) {
                            this.selected_preference_list.push(key);
                        }
                    }
                }
                //商社取得
                this.fetchRetailers()

                //ユーザ製品表示項目に各項目をセット
                this.generateSpecPreferenceList();
            })
            .finally(() => {
                this.loading--;
            });
        },
        //ユーザ製品表示項目に各項目をセット
        generateSpecPreferenceList() {
            this.preference_list.catalog_spec_labels.splice(0);
            this.preference_list.standard_spec_labels.splice(0);
            this.preference_list.production_spec_labels.splice(0);

            //カタログ図規格項目
            this.preference_list.catalog_spec_labels = PreferenceControlService.generateCatalogSpecPreferenceList(this.browsing_subgroup.catalog_spec_labels, this.revision);
            //規格図規格項目
            this.preference_list.standard_spec_labels = PreferenceControlService.generateStandardSpecPreferenceList(this.browsing_subgroup.standard_spec_labels, this.revision);
            //製作図規格項目
            this.preference_list.production_spec_labels = PreferenceControlService.generateProductionSpecPreferenceList(this.browsing_subgroup.production_spec_labels, this.revision);

            //各規格項目が表示設定されていたら
            if (this.preference.catalogs_specs === PreferenceType.SHOW) {
                for (let label of this.preference_list.catalog_spec_labels) {
                    this.selected_catalog_spec_label_list.push(label.key);
                }
            }

            if (this.preference.standard_specs === PreferenceType.SHOW) {
                for (let label of this.preference_list.standard_spec_labels) {
                    this.selected_standard_spec_label_list.push(label.key);
                }
            }
            //製品情報閲覧権限を持ち、かつ 製品表示項目表示 がONの場合
            if (this.$store.getters['auth/canViewProduction']() && this.preference.production_specs === PreferenceType.SHOW) {
                for (let label of this.preference_list.production_spec_labels) {
                    this.selected_production_spec_label_list.push(label.key);
                }
            }

        },
        //ユーザ製品表示項目に商社別項目をセット（前提: preferenceとretailersが取得できていること）
        generateRetailerPreferenceList() {
            this.preference_list.retailers = PreferenceControlService.generateProductRetailerPreferenceList(this.retailers);
            //表示項目設定で「表示」選択されていたら
            if (this.preference.is_retailer_selling === PreferenceType.SHOW) {
                for (let product_retailer of this.preference_list.retailers) {
                    if (product_retailer.key.indexOf('is_retailer_selling') > -1) {
                        this.selected_product_retailer_list.push(product_retailer.key);
                    }
                }
            }
            if (this.preference.retailer_product_code === PreferenceType.SHOW) {
                for (let product_retailer of this.preference_list.retailers) {
                    if (product_retailer.key.indexOf('retailer_product_code') > -1) {
                        this.selected_product_retailer_list.push(product_retailer.key);
                    }
                }
            }
            if (this.preference.retailer_product_name === PreferenceType.SHOW) {
                for (let product_retailer of this.preference_list.retailers) {
                    if (product_retailer.key.indexOf('retailer_product_name') > -1) {
                        this.selected_product_retailer_list.push(product_retailer.key);
                    }
                }
            }
            if (this.preference.retailer_rate === PreferenceType.SHOW) {
                for (let product_retailer of this.preference_list.retailers) {
                    if (product_retailer.key.indexOf('retailer_rate') > -1) {
                        this.selected_product_retailer_list.push(product_retailer.key);
                    }
                }
            }
            if (this.preference.retailer_price === PreferenceType.SHOW) {
                for (let product_retailer of this.preference_list.retailers) {
                    if (product_retailer.key.indexOf('retailer_price') > -1) {
                        this.selected_product_retailer_list.push(product_retailer.key);
                    }
                }
            }
        },
        //製品説明モーダルをオープン
        openDescriptionModal(product) {
            this.description_modal = true;
            this.target_product = product;
        },
        //製品説明モーダルをクローズ
        closeDescriptionModal() {
            this.description_modal = false;
        },
        //製作図表示モーダルをオープン
        openProductionModal(product) {
            this.production_modal = true;
            this.target_product = product;
        },
        //製品説明モーダルをクローズ
        closeProductionModal() {
            this.production_modal = false;
        },
        //共有メモを登録
        saveNote() {
            //バリデーション
            if (!this.additional_content) {
                this.showErrorMessage('メモが未入力です');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/product-group/${this.product_group_id}/note`, {
                content: this.additional_content
            })
            .then(response => {
                this.additional_content = null;
                this.product_group.notes.push(new ProductNote(response.data));
                this.showMessage('登録しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //共有メモを削除
        removeNote() {
            this.startScreenLoading();

            let target_notes = this.product_group.notes.splice(this.target_index, 1);
            let product_note_id = target_notes[0].product_note_id;

            this.$http.delete(`/product-group/${this.product_group_id}/note/${product_note_id}`)
            .then(() => {
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //言語モード変更
        changeModeJp() {
            this.mode_lang = 'jp';
        },
        changeModeEn() {
            this.mode_lang = 'en';
        },
        //コンファームモーダル用
        setTargetIndex(index) {
            this.target_index = index;
        },
        unsetTargetIndex() {
            this.target_index = null;
        },

    }
}
</script>

<style scoped>
    .image-link {text-decoration: none !important; display: flex; flex-direction: column !important; margin-bottom: 1rem;}
    .image-link .badge {border: 1px solid #eee; border-bottom: 0; border-radius: 0.25rem 0.25rem 0 0; padding: 0.3rem 0 !important;}
    .image-link .img {flex: 1 0 auto; display: flex; align-items: center; border: 1px solid #eee; background-color: #fff; padding: .2rem;}
    .image-link .img img {min-width: 50%; margin: auto;}
    .min-vw-25 {min-width: 25vw;}
    .image-link .file-name { font-size: .7rem; color: #555; line-height: 1rem; border: 1px solid #eee; border-top: 0; border-radius: 0 0 0.25rem 0.25rem; padding: .2rem .5rem; word-break: break-all; }
    .image-link .file-name span:first-child { font-size: .8rem;}
    .btn-left, .btn-right {padding: 0.25rem 0.5rem; font-size: 0.875rem;}
    .btn-left {border-radius: 0.2rem 0 0 0.2rem;}
    .btn-right {border-radius: 0 0.2rem 0.2rem 0;}
    .retailer-name {min-width: 20%;}
    .force-wrap {width: 100%;}
    .form-check-input[type=checkbox] {margin-right: .3rem;}
    .view-textarea {white-space: pre-wrap; min-height: 2rem;}
    .zoom-body {height: 75vh;}
    .popup { position: relative; }
    .popup:hover { cursor: pointer; }
    .popup:hover span { display: block; }
    .popup span { display: none; position: absolute; top: -1.5rem; left: calc(50%); border: .5px solid rgb(0 0 0 / 20%); box-shadow: 0 0.125rem 0.25rem #ccc !important;}
    .rotate:before {transform: scale(-1, 1);}
    .large-z-index { z-index: 99999 !important }
    .modal-body {min-height: 20vh;}
    .hover:hover { cursor: pointer; }
    .bg-info-light {background-color: rgba(var(--bs-info-rgb), .3) !important}
    .bg-warning-light {background-color: rgba(var(--bs-warning-rgb), .3) !important;}
    .bg-success-light {background-color: rgba(var(--bs-success-rgb), .3) !important;}
    .table-scrollable {overflow: auto; max-height: calc(100vh - 100px); -webkit-overflow-scrolling: touch; position: relative;}
    .table-scrollable thead th:first-child {position: sticky; top: 0; left: 0; z-index: 3;}
    .table-scrollable thead th {position: sticky; top: 0; z-index: 2;}
    .table-scrollable tbody td:first-child {position: sticky; left: 0; z-index: 2; background: #FFF;}
    .pdf-wrap {max-width: calc(100% - 2rem); max-height: 100%; height: 100%;overflow: hidden;}
    .pdf-wrap img {max-width: 100%; max-height: 100%; min-width: 80% !important;}
    .pdf-wrap:after {content: '\F63D'; font-family: bootstrap-icons; font-weight: 400; font-size: 2rem; position: absolute; right: 1.5rem; color: #dc3545;}
    .sid-small {font-size: .65rem;}
</style>
