import { isNud } from '@/utilities/typing';
import Preferences from '@/models/services/preferences.json';

/**
 * 製品表示項目サービス
 */
class PreferenceControlService
{
    //全てのPreferencesを返す
    static all() {
        return Preferences;
    }

    //keyをもとにオブジェクトを返す
    static get(key) {
        let preference = Preferences.find(preference => preference.key === key);

        if (isNud(preference)) {
            return null;
        }

        return preference;
    }

    //基本情報のみのオブジェクトの配列を返す
    static getBasics() {
        let array = [];
        for (let preference of Preferences) {
            if (preference.group === 'basic') {
                array.push(preference);
            }
        }

        return array;
    }

    //価格情報のみのオブジェクトの配列を返す
    static getPrices() {
        let array = [];
        for (let preference of Preferences) {
            if (preference.group === 'price') {
                array.push(preference);
            }
        }

        return array;
    }

    //規格情報のみのオブジェクトの配列を返す
    static getSpecs() {
        let array = [];
        for (let preference of Preferences) {
            if (preference.group === 'spec') {
                array.push(preference);
            }
        }

        return array;
    }

    //商社別情報のみのオブジェクトの配列を返す
    static getProductRetailers() {
        let array = [];
        for (let preference of Preferences) {
            if (preference.group === 'retailer') {
                array.push(preference);
            }
        }

        return array;
    }

    //グループが基本情報かどうか
    static isBasic(preference) {
        if (preference.group === 'basic') {
            return true;
        }

        return false;
    }
    //グループが価格情報かどうか
    static isPrice(preference) {
        if (preference.group === 'price') {
            return true;
        }

        return false;
    }
    //グループが規格項目情報かどうか
    static isSpec(preference) {
        if (preference.group === 'spec') {
            return true;
        }

        return false;
    }
    //グループが商社別情報かどうか
    static isRetailer(preference) {
        if (preference.group === 'retailer') {
            return true;
        }

        return false;
    }

    //カタログ図規格項目の初期表示セット（revisionでアクセスした場合はrevisionが渡り、公開情報でアクセスした場合は、revision = null が渡る）
    static generateCatalogSpecPreferenceList(catalog_spec_labels, revision) {
        if (!catalog_spec_labels.length) {
            return [];
        }

        let catalog_spec_list = [];
        for (let catalog_spec_label of catalog_spec_labels) {
            catalog_spec_list.push({
                key: 'catalog_spec-' + (isNud(revision) ? catalog_spec_label.product_catalog_spec_label_id : catalog_spec_label.product_catalog_spec_label_revision_id),
                label: catalog_spec_label.spec_label.label_name,
                group: "catalog_spec",
            });
        }
        return catalog_spec_list;
    }

    //規格図規格項目の初期表示セット
    static generateStandardSpecPreferenceList(standard_spec_labels, revision) {
        if (!standard_spec_labels.length) {
            return [];
        }

        let standard_spec_list = [];
        for (let standard_spec_label of standard_spec_labels) {
            standard_spec_list.push({
                key: 'standard_spec-' + (isNud(revision) ? standard_spec_label.product_standard_spec_label_id : standard_spec_label.product_standard_spec_label_revision_id),
                label: standard_spec_label.spec_label.label_name,
                group: "standard_spec",
            });
        }
        return standard_spec_list;
    }

    //製作図規格項目の初期表示セット
    static generateProductionSpecPreferenceList(production_spec_labels, revision) {
        if (!production_spec_labels.length) {
            return [];
        }

        let production_spec_list = [];
        for (let production_spec_label of production_spec_labels) {
            production_spec_list.push({
                key: 'production_spec-' + (isNud(revision) ? production_spec_label.product_production_spec_label_id : production_spec_label.product_production_spec_label_revision_id),
                label: production_spec_label.spec_label.label_name,
                group: "production_spec",
            });
        }
        return production_spec_list;
    }

    //商社別規格項目の初期表示セット
    static generateProductRetailerPreferenceList(retailers) {
        if (!retailers.length) {
            return [];
        }
        let product_retailer_list = [];
        let __retailer_name = ''; //front表示用
        retailers.forEach((retailer) => {
            let __the_first = true; //front表示用
            //新たな商社が現れたとき
            if (__retailer_name !== retailer.retailer_name) {
                __retailer_name = retailer.retailer_name;
            }
            product_retailer_list.push(
                {
                    key: 'retailer_' + retailer.retailer_id + '_is_retailer_selling',
                    label: '販売',
                    group: "product_retailer",
                    __retailer_name: __retailer_name,
                    __the_first: __the_first,
                },
                {
                    key: 'retailer_' + retailer.retailer_id + '_retailer_product_code',
                    label: '製品コード',
                    group: "product_retailer",
                    __retailer_name: __retailer_name,
                },
                {
                    key: 'retailer_' + retailer.retailer_id + '_retailer_product_name',
                    label: '製品名',
                    group: "product_retailer",
                    __retailer_name: __retailer_name,
                },
                {
                    key: 'retailer_' + retailer.retailer_id + '_retailer_rate',
                    label: '掛率',
                    group: "product_retailer",
                    __retailer_name: __retailer_name,
                },
                {
                    key: 'retailer_' + retailer.retailer_id + '_retailer_price',
                    label: '価格',
                    group: "product_retailer",
                    __retailer_name: __retailer_name,
                },
            );
        });

        return product_retailer_list;
    }
}

export default PreferenceControlService;